export const initWardState = {

    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    educationSectorList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedEducationSector: {
        ward: "",
        data: [],
    },
    healthList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHealth: {
        ward: "",
        data: [],
    },
    unhealthyList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedUnhealthy: {
        ward: "",
        data: [],
    },
    disableList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedDisable: {
        ward: "",
        data: [],
    },
    disableCardYesNoList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedDisableCardYesNo: {
        ward: "",
        data: [],
    },
    houseCountList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHouseCount: {
        ward: "",
        data: [],
    },
    roofTypeList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedRoofType: {
        ward: "",
        data: [],
    },
    waterResourceList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedWaterResource: {
        ward: "",
        data: [],
    },
    cookingSourceList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedCookingSource: {
        ward: "",
        data: [],
    },
    electricSourceList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedElectricSource: {
        ward: "",
        data: [],
    },
    toiletList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedToilet: {
        ward: "",
        data: [],
    },
    garbageList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedGarbage: {
        ward: "",
        data: [],
    },
    annualIncomeList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAnnualIncome: {
        ward: "",
        data: [],
    },
    annualExpenseList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAnnualExpense: {
        ward: "",
        data: [],
    },
    houseOwnerGenderList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHouseOwnerGender: {
        ward: "",
        data: [],
    },
    abroadList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAbroad: {
        ward: "",
        data: [],
    },
    occupationList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedOccupation: {
        ward: "",
        data: [],
    },
    educationList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedEducation: {
        ward: "",
        data: [],
    },
    ethnicsList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedEthnics: {
        ward: "",
        data: [],
    },
    livingTypeList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedLivingType: {
        ward: "",
        data: [],
    },
    religionList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedReligion: {
        ward: "",
        data: [],
    },
    motherTongueList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedMotherTongue: {
        ward: "",
        data: [],
    },
    isSchoolGoingList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedIsSchoolGoing: {
        ward: "",
        data: [],
    },
    maritalList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedMarital: {
        ward: "",
        data: [],
    },
    dashboardPercentageList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedDashboardPercentage: {
        ward: "",
        data: [],
    },
    bankAccountYesNoList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedBankAccountYesNo: {
        ward: "",
        data: [],
    },
    isLoanList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedIsLoan: {
        ward: "",
        data: [],
    },
    agricultureProductionOrNotList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAgricultureProductionOrNot: {
        ward: "",
        data: [],
    },
    livestockRaisingList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedLivestockRaising: {
        ward: "",
        data: [],
    },
    animalCountList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAnimalCount: {
        ward: "",
        data: [],
    },
    houseCertificateList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHouseCertificate: {
        ward: "",
        data: [],
    },
    houseMapPassList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHouseMapPass: {
        ward: "",
        data: [],
    },
    institutionCountList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedInstitutionCount: {
        ward: "",
        data: [],
    },
    ageList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAge: {
        ward: "",
        data: [],
    },
    socialAllowanceList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedSocialAllowance: {
        ward: "",
        data: [],
    },
    annualAgriIncomeList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAnnualAgriIncome: {
        ward: "",
        data: [],
    },
    annualAgriExpenseList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedAnnualAgriExpense: {
        ward: "",
        data: [],
    },
    skillList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedSkill: {
        ward: "",
        data: [],
    },
    disableReasonList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedDisableReason: {
        ward: "",
        data: [],
    },
    houseTypeList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedHouseType: {
        ward: "",
        data: [],
    },
    foodForMonthList: {
        title: {
            english: "",
            nepali: ""
        },
        ward: [],
    },
    selectedFoodForMonth: {
        ward: "",
        data: [],
    },
    wardDetails: {
        ward: "",
        data: [
            {
                title: {
                    nepali: "",
                    english: "",
                },
                count: 0,
            },
            {
                title: {
                    nepali: "",
                    english: "",
                },
                count: 0,
            },
            {
                title: {
                    nepali: "",
                    english: "",
                },
                count: 0,
            },
            {
                title: {
                    nepali: "",
                    english: "",
                },
                count: 0,
            },
        ],
    },
    lastError: null,
    selectedLanguage: ""
}