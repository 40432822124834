
export const toFormattedNumber = value => {
    if (value) {
        value = value.toString();
        let afterDecimal = ''
        if (value.includes('.')) {
            let values = value.split('.');
            value = values[0];
            afterDecimal = values[1];
        }
        var lastThree = value.substring(value.length - 3);
        var otherNumbers = value.substring(0, value.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (afterDecimal !== '') {
            res += '.' + afterDecimal;
        }
        return res;
    }
    return 0
}