import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import ProgressBarCard from '../components/ProgressBarCard';
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class ProgressBarStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ageCategories: []
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            let ageCategories = []
            let subTitleKey = "subtitle" + this.capitalizeFirstLetter(nextProps.allDetail.selectedLanguage)
            nextProps.detail.data.map((category, index) => {
                let color = "";
                switch (index) {
                    case 0: {
                        color = "info";
                        break;
                    }
                    case 1: {
                        color = "success";
                        break;
                    }
                    case 2: {
                        color = "danger";
                        break;
                    }
                    default: {
                        color = "warning";
                        break;
                    }
                }
                let displayVal = {
                    title: category[nextProps.allDetail.selectedLanguage],
                    value: category.value,
                    smallText: category[subTitleKey],
                    color
                };
                ageCategories.push(displayVal)
            })
            this.setState({ ageCategories })
        }

    }
    componentDidMount() {
        this.props.dispatch(actions.fetchDashboardPercentage());

    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    render() {
        return (

            <div className="pb-5 col-md-6 col-lg-4">
                <div className="row">
                    {
                        this.state.ageCategories.map((category, index) => {
                            return (
                                <ProgressBarCard category={category} key={index} />
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: state.ward.selectedDashboardPercentage,
    allDetail: state.ward,
})
export default connect(mapStateToProps)(ProgressBarStats);