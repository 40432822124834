import React from "react";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import L from "leaflet";

import iconUrl from "../image/pin24.png";
import educationUrl from "../image/education.png";
import officeUrl from "../image/office.png";
import industriesUrl from "../image/industries.png";
import infrastructureUrl from "../image/infrastructure.png";
import workshopUrl from "../image/workshop.png";
import agricultureUrl from "../image/agriculture.png";
import homestayUrl from "../image/homestay.png";
import economicUrl from "../image/economic.png";
import healthUrl from "../image/health.png";
import { BASE_URL } from "../../redux/axiosHandler";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function GeoJsonLayer(props) {
	const institutePopUp = (properties) => {
		const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.photo}`;
		return (
			<div>
				<img
					src={baseUrl}
					alt={properties.officeName}
					width={300}
					height={200}
					style={{ objectFit: "cover" }}
					onError={(event) => {
						event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
						event.onerror = null;
					}}
				/>
				<br />
				<br />
				<b>Office Name:</b> {properties.officeName}
				<br />
				<b>Ward Number:</b> {properties.wardNumber}
				<br />
				<b>Institution Type:</b> {properties.institutionsType}
				<br />
			</div>
		);
	};
	//console.log(props.data);
	if (
		props.data.features.findIndex((each) => each.geometry.type === "Point") !==
		-1
	) {
		return props.data.features.map((eachFeature) => {
			const properties = eachFeature.properties;
			const eachCoordinates = eachFeature.geometry.coordinates;
			var pointerIcon = L.Icon.extend({
				options: {
					iconAnchor: [12, 24],
				},
			});

			var icon;
			if (properties.institutionsType === "education") {
				icon = new pointerIcon({
					iconUrl: educationUrl,
				});
			} else if (
				properties.institutionsType === "gov_office" ||
				properties.institutionsType === "offices" ||
				properties.institutionsType === "palika_related_office"
			) {
				icon = new pointerIcon({ iconUrl: officeUrl });
			} else if (properties.institutionsType === "industries") {
				icon = new pointerIcon({ iconUrl: industriesUrl });
			} else if (properties.institutionsType === "education") {
				icon = new pointerIcon({ iconUrl: agricultureUrl });
			} else if (properties.institutionsType === "economic") {
				icon = new pointerIcon({ iconUrl: economicUrl });
			} else if (properties.institutionsType === "forest") {
				icon = new pointerIcon({ iconUrl: homestayUrl });
			} else if (properties.institutionsType === "cultural_heritage") {
				icon = new pointerIcon({ iconUrl: workshopUrl });
			} else if (
				properties.institutionsType === "health" ||
				properties.institutionsType === "public_places"
			) {
				icon = new pointerIcon({ iconUrl: healthUrl });
			} else if (properties.institutionsType === "infrastructure") {
				icon = new pointerIcon({ iconUrl: infrastructureUrl });
			} else {
				icon = new pointerIcon({ iconUrl: iconUrl });
			}

			return (
				<Marker position={[eachCoordinates[1], eachCoordinates[0]]} icon={icon}>
					<Popup>{institutePopUp(properties)}</Popup>
				</Marker>
			);
		});
	}

	return <GeoJSON {...props} />;
}
