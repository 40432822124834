import * as requestFromServer from "./WardCrud";
import { wardsSlice, callTypes } from "./WardSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const { actions } = wardsSlice;

export const selectLanguage = (language) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	dispatch(actions.languageSelected({ language }));
};
export const fetchPopulationDetails = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));

	return requestFromServer
		.getPopulationCount()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const wardDetails = entities.ward[entities.ward.length - 1];
				dispatch(actions.populationFetched({ entities, wardDetails }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find wards";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAbroad = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAbroad()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.abroadFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchHealth = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHealth()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.healthFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchUnhealthy = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getUnhealthy()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.unhealthyFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchDisable = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getDisable()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.disableFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchDisableCardYesNo = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getDisableCardYesNo()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.disableCardYesNoFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchEducationSector = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getEducationSector()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.educationSectorFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchHouseCount = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHouseCount()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[0];
				dispatch(actions.houseCountFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchRoofType = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getRoofType()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[0];
				dispatch(actions.roofTypeFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAnnualIncome = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAnnualIncome()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.annualIncomeFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAnnualExpense = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAnnualExpense()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.annualExpenseFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchOccupation = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getOccupation()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.occupationFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchEducation = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getEducation()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.educationFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchEthnics = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getEthnics()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.ethnicsFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchLivingType = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getLivingType()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.livingTypeFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchReligion = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getReligion()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.religionFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchMotherTongue = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getMotherTongue()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.motherTongueFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchIsSchoolGoing = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getIsSchoolGoing()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.isSchoolGoingFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchMarital = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getMarital()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.maritalFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchHouseOwnerGender = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHouseOwnerGender()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.houseOwnerGenderFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchWardDetails = (id) => (dispatch) => {
	dispatch(actions.wardSelected({ id }));
};
export const fetchDashboardPercentage = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getDashboardPercentage()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.dashboardPercentageFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchBankAccountYesNo = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getBankAccountYesNo()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.bankAccountYesNoFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchIsLoan = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getIsLoan()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.isLoanFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchAgricultureProductionOrNot = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAgricultureProductionOrNot()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(
					actions.agricultureProductionOrNotFetched({ entities, detail })
				);
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchLivestockRaising = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getLivestockRaising()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.livestockRaisingFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchAnimalCount = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAnimalCount()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.animalCountFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchHouseCertificate = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHouseCertificate()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.houseCertificateFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchHouseMapPass = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHouseMapPass()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.houseMapPassFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchWaterResource = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getWaterResource()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.waterResourceFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchCookingSource = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getCookingSource()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.cookingSourceFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchElectricSource = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getElectricSource()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.electricSourceFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchToilet = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getToilet()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.toiletFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchGarbage = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getGarbage()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.garbageFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchInstitutionCount = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getInstitutionCount()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.institutionCountFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAge = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAge()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.ageFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchSocialAllowance = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getSocialAllowance()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.socialAllowanceFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAnnualAgriIncome = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAnnualAgriIncome()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.annualAgriIncomeFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchAnnualAgriExpense = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAnnualAgriExpense()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.annualAgriExpenseFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchSkill = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getSkill()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.skillFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchDisableReason = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getDisableReason()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.disableReasonFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchHouseType = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getHouseType()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.houseTypeFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
export const fetchFoodForMonth = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getFoodForMonth()
		.then((response) => {
			if (response.data.success) {
				const entities = response.data.data;
				const detail = entities.ward[entities.ward.length - 1];
				dispatch(actions.foodForMonthFetched({ entities, detail }));
			} else {
				let error = {
					clientMessage: "",
				};
				error.clientMessage = response.data.message;
				dispatch(actions.catchError({ error, callType: callTypes.list }));
			}
		})
		.catch((error) => {
			error.clientMessage = "Can't find data";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};
