import React, { Component } from "react";
import { Card } from "react-bootstrap";
import PersonCard from "../components/PersonCard";

class Representative extends Component {
	constructor(props) {
		super(props);
		this.state = {
			people: [],
		};
	}
	componentDidMount() {
		const people = [
			{
				name: "शम्भु गिरी",
				nameEnglish: "Sambhu Giri",
				designation: "गाउँपालिका अध्यक्ष",
				designationEnglish: "Chairperson",
				email: "shambhugiri.dangisharan@gmail.com",
				phone: "९८५७८२०९६०",
				phoneEnglish: "9857820960",
				address: "दंगीशरण गाउँपालिका दाङ, लुम्बिनी प्रदेश, नेपाल",
				addressEnglish: "Dangisharan rural municipality dang",
				image: "media/hamropalika/people/st1.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
			{
				name: "पिमा कुमारी खड्का",
				nameEnglish: "Pima Kumari Khadka",
				designation: "गाउँपालिका उपाध्यक्ष",
				designationEnglish: "Vice-chariperson",
				email: "pimakhadka1@gmail.com",
				phone: "९८४४९३५०६६",
				phoneEnglish: "9844935066",
				address: "दंगीशरण गाउँपालिका दाङ, लुम्बिनी प्रदेश, नेपाल",
				addressEnglish: "Dangisharan rural municipality dang",
				image: "media/hamropalika/people/st2.jpg",
				containerClass: "col-sm-6 col-md-12 col-xl-6",
			},
		];
		this.setState({ people });
	}
	render() {
		return (
			<div className="row h-100">
				{this.state.people.map((person, index) => {
					return <PersonCard data={person} key={index} />;
				})}
			</div>
		);
	}
}

export default Representative;
