import React, { Component } from "react";
import { connect } from "react-redux";
import BarGraphCard from "../components/BarGraphCard";
import * as actions from "../../app/modules/Hamropalika/_redux/WardRedux";

class Health extends Component {
	constructor(props) {
		super(props);
		this.state = {
			barData: [],
		};
	}
	componentDidMount() {
		this.props.dispatch(actions.fetchHealth());
		this.props.dispatch(actions.fetchUnhealthy());
		this.props.dispatch(actions.fetchDisable());
		this.props.dispatch(actions.fetchDisableCardYesNo());
		this.props.dispatch(actions.fetchDisableReason());
	}
	componentWillReceiveProps(nextProps) {
		// You don't have to do this check first, but it can help prevent an unneeded
		let femaleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "महिला" : "Female";
		let maleTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "पुरुष" : "Male";
		let thirdTitle =
			nextProps.detail.selectedLanguage == "nepali" ? "अन्य" : "Other";
		if (
			nextProps.detail.selectedHealth !== this.props.detail.selectedHealth ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedHealth?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedHealth?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedHealth?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedHealth?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			// //console.log("female: ", femaleData, "male: ", maleData, "third: ", thirdData, "label: ", labelData,)
			let healthData = {
				id: 1,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.healthList.title[nextProps.detail.selectedLanguage],
				categories: labelData,
				detail: "Health",
				xaxis: "Health Condition",
				xaxis_nepali: "स्वास्थ्य अवस्था",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 1);
				if (!prevWardData) {
					barData.push(healthData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = healthData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedUnhealthy !==
			this.props.detail.selectedUnhealthy ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedUnhealthy?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedUnhealthy?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedUnhealthy?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedUnhealthy?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let unhealthyData = {
				id: 2,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.unhealthyList.title[
					nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "Unhealthy",
				xaxis: "Disease Type",
				xaxis_nepali: "रोगको प्रकार",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 2);
				if (!prevWardData) {
					barData.push(unhealthyData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = unhealthyData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedDisable !== this.props.detail.selectedDisable ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedDisable?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedDisable?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedDisable?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedDisable?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage];
				}
			);
			let disableData = {
				id: 3,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.disableList.title[nextProps.detail.selectedLanguage],
				categories: labelData,
				detail: "Disable",
				xaxis: "Disability Type",
				xaxis_nepali: "अपाङ्गताको प्रकार",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 3);
				if (!prevWardData) {
					barData.push(disableData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = disableData;
				}
				return {
					barData,
				};
			});
		}
		// API remaining
		if (
			nextProps.detail.selectedDisableCardYesNo !==
			this.props.detail.selectedDisableCardYesNo ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedDisableCardYesNo?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedDisableCardYesNo?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedDisableCardYesNo?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedDisableCardYesNo?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage] || "";
				}
			);
			let disableCardYesNoData = {
				id: 4,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.disableCardYesNoList.title[
					nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "DisableCardYesNo",
				xaxis: "Acquired disability card or not",
				xaxis_nepali: "अपाङ्गता परिचय पत्र लिएको/नलिएको",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 4);
				if (!prevWardData) {
					barData.push(disableCardYesNoData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = disableCardYesNoData;
				}
				return {
					barData,
				};
			});
		}
		if (
			nextProps.detail.selectedDisableReason !==
			this.props.detail.selectedDisableReason ||
			nextProps.detail.selectedLanguage !== this.props.detail.selectedLanguage
		) {
			let femaleData = nextProps.detail.selectedDisableReason?.data.female?.map(
				({ value }) => value
			);
			let maleData = nextProps.detail.selectedDisableReason?.data.male?.map(
				({ value }) => value
			);
			let thirdData = nextProps.detail.selectedDisableReason?.data.third?.map(
				({ value }) => value
			);
			let labelData = nextProps.detail.selectedDisableReason?.data.third?.map(
				(datum) => {
					return datum[nextProps.detail.selectedLanguage] || "";
				}
			);
			let disableReasonData = {
				id: 5,
				values: [
					{
						name: femaleTitle,
						data: femaleData,
					},
					{
						name: maleTitle,
						data: maleData,
					},
					{
						name: thirdTitle,
						data: thirdData,
					},
				],
				title:
					nextProps.detail.disableReasonList.title[
					nextProps.detail.selectedLanguage
					],
				categories: labelData,
				detail: "DisableReason",
				xaxis: "Reasons",
				xaxis_nepali: "कारण",
			};
			this.setState((previousState) => {
				let barData = previousState.barData;
				let prevWardData = previousState.barData.find((i) => i.id == 5);
				if (!prevWardData) {
					barData.push(disableReasonData);
				} else {
					let prevWardDataIndex = previousState.barData.indexOf(prevWardData);
					barData[prevWardDataIndex] = disableReasonData;
				}
				return {
					barData,
				};
			});
		}
	}
	render() {
		return (
			<div className="row">
				{this.state.barData?.map((datum, index) => {
					return (
						<BarGraphCard key={index} datum={datum} section="health_bar_" />
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	detail: state.ward,
});
export default connect(mapStateToProps)(Health);
